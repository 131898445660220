.unit-card-v2 {

	.content {
		padding: 10px;
		display: flex;
		flex-direction: column;
		min-height: 190px;
		justify-content: space-between;
		border: 1px solid var(--color-grey-normal);

		background-color: var(--white);

		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);

			}

			.btn {
				font-size: var(--font-size-md);
				min-width: 142px;
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}


	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;

		.rating {
			position: absolute;
			right: 0px;
			bottom: 0px;
			padding: 7px 7px 7px 12px;
			z-index: 2;
			background-color: rgba(0, 0, 0, .5);
			font-size: var(--font-size-md);

			&.style2 {
				display: inline-flex;

			}

			.star2 {
				margin-right: 3px;
			}

			span {
				color: var(--color-white);
			}

			.fa {
				color: var(--color-primary);
			}

			.ratings {
				display: inline-flex;
				margin-left: 3px;
			}

		}

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;

		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.rules {
			position: absolute;
			bottom: 10px;
			left: 10px;
			background-color: white;
			padding: 0;
			border-radius: 5px;
			color: var(--font-color-main);

			.fa {
				padding-top: 8px;
				padding-bottom: 8px;
				padding-right: 5px;
			}

			.fa:first-child {
				padding-left: 5px;
			}

			.fa:last-child {
				padding-right: 5px;
			}
		}

		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}
}