.facility-gallery-v1 {

	.unit-pr-dt-gallery {
		@media (min-width:993px) {
			display: flex;
			justify-content: space-between;

		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 550px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 75%;

		@media (max-width:767px) {
			height: 400px;
		}


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}

	.v-img-gallery {
		position: relative;
		top: -50px;
		z-index: 1;
		width: 100%;
		display: inline-flex;
		justify-content: center;

		@media (min-width:992px) {
			margin-bottom: -100px;
		}


		@media (max-width:380px) {
			top: -45px;
		}

		a {
			position: relative;
			display: inline-block;
			border: 3px solid var(--white);
			box-shadow: 3px 3px 2px rgba(0, 0, 0, .1);

			&:nth-child(n + 4) {
				display: none;
			}

			&:nth-child(3) {
				&:before {
					z-index: 5;
					content: "+ mehr";
					position: absolute;
					color: var(--white);
					background-color: rgba(0, 0, 0, 0.5);
					width: 100%;
					height: 100%;
					text-align: center;
					display: inline-block;
					padding-top: 25%;
					font-weight: 700;
					font-size: 20px;
				}
			}
		}

		.img-thumbnail {
			border: none;
			padding: 0;
			width: 100%;

		}

	}

}